<!-- 服务记录 -->
<template>
  <div class="service_log">
    <div class="info_flex">
      <!-- <div class="tab_name_flex">

        <div class="tab_name" @click="tabclick(index)" :class="tabindex==index?'bg':''" v-for="(item,index) in tablist" :key="index">
          <span>{{ item.name }}</span>
        </div>
      </div> -->
      <div class="info_flex_box">
        <!-- <div class="tab_name_flex"> -->
        <!-- <div
            class="tab_name"
            @click="tabclick(index)"
            :class="tabindex == index ? 'bg' : ''"
            v-for="(item, index) in tablist"
            :key="index"
          >
            <span>{{ item.name }}</span>
          </div> -->
        <!-- </div> -->
        <span class="name_css">共{{ total }}条服务记录</span>
        <div class="main_box">
          <span @click="clickMain"> 按记录时间</span>
          <div class="main" style="margin-left: 5px">
            <span
              class="arrUp"
              @click="clickUp1()"
              :style="{ 'border-bottom-color': color1 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown1()"
              :style="{ 'border-top-color': color2 }"
            ></span>
          </div>
        </div>
        <div style="margin-left: 20px">
          <span>服务记录：</span>
          <el-select v-model="queryInfo.isRequirement" @change="search" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="search_right">
        <el-input
          placeholder="请输入需求标题"
          v-model="queryInfo.queryCondition"
          class="input-with-select"
          style="width: 440px"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="home_context_flex_box">
      <div class="home_context_flex">
        <div class="box_history" v-for="(item, index) in list" :key="index">
          <div class="box_history_flex">
            <div>
              <img
                class="images_samll"
                v-if="!item.images"
                src="../../../assets/image/fuwu1.png"
                alt=""
              />
              <img class="images_samll" v-else :src="item.images" alt="" />
            </div>
            <div class="box_history_left">
              <div class="box_history_top">
                <span class="companyName">{{ item.title }}</span>
              </div>
              <div class="compay_flex">
                <!-- <div class="compay_flex_item" v-for="(el, i) in item.labelList" :key="i">
                  <span>{{ el }}</span>
                </div> -->
              </div>
              <div class="dis_box">
                <div class="compay_flexs">
                  <span>{{ item.requirementDescribe }}</span>
                </div>
              </div>
            </div>
            <!-- <div class="isRequirement po_isRequirement" v-if="item.isRequirement">
              <span>需求</span>
            </div>
            <div class="isRequirement_false po_isRequirement" v-else>
              <span>非需求</span>
            </div> -->
            <div class="isRequirement po_isRequirement" v-if="item.isRequirement == '有明确线索'">
              <span>{{ item.isRequirement }}</span>
            </div>
            <div class="isRequirement_false po_isRequirement" v-if="item.isRequirement == '有需求'">
              <span
                ><span>{{ item.isRequirement }}</span></span
              >
            </div>
            <div
              class="isRequirement_false1 po_isRequirement"
              v-if="item.isRequirement == '无线索'"
            >
              <span
                ><span>{{ item.isRequirement }}</span></span
              >
            </div>
            <div
              class="isRequirement_false2 po_isRequirement"
              v-if="item.isRequirement == '有潜在线索'"
            >
              <span
                ><span>{{ item.isRequirement }}</span></span
              >
            </div>
          </div>
          <div class="right_w">
            <div class="right_flex">
              <div class="edit">
                <span @click="edit(item)">详情</span>
              </div>
            </div>
            <div class="time_css">
              <span>记录时间：</span>
              <span>{{ item.createTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="需求记录详情"
      :visible.sync="DialogVisible"
      width="700px"
      class="account_dialog"
      :close-on-click-modal="false"
    >
      <el-form label-width="100px" :model="addform" ref="form">
        <el-form-item label="需求标题" prop="">
          <span class="name_center">{{ addform.title }}</span>
        </el-form-item>
        <el-form-item label="需求描述" prop="">
          <span class="name_center">{{ addform.requirementDescribe }}</span>
        </el-form-item>
        <el-form-item label="现场照片" prop="">
          <div v-if="addform.imageList">
            <div style="display: flex" v-for="(item, index) in addform.imageList" :key="index">
              <img style="margin-right: 5px" width="100" height="100" :src="item" alt="" />
            </div>
          </div>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="DialogVisible = false">确 定</el-button>
        <el-button @click="DialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { selectRequirementPage, selectRequirementById } from '@/api/enterpriseCustomer.js'

const defaultQueryInfo = Object.freeze({
  customerId: 0,
  orderByParam: 1,
  companyId: '',
  pageNum: 1,
  pageSize: 10,
  queryCondition: '',
  isRequirement: ''
})
export default {
  name: 'ServiceLog',
  components: {},
  props: {
    id: {
      type: Number
    },
    customerId: {
      type: Number
    },
    orgCompanyId: {
      type: String
    }
  },
  watch: {
    orgCompanyId: {
      handler(val) {
        if (val) {
          this.search()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      DialogVisible: false,
      queryInfo: { ...defaultQueryInfo }, // 服务记录查询一览
      tabindex: 0,
      tablist: [
        {
          name: '服务记录',
          value: ''
        },
        {
          name: '需求',
          value: true
        },
        {
          name: '非需求',
          value: false
        }
      ],
      options: [
        { text: '全部', value: '' },
        { text: '无线索', value: '无线索' },
        { text: '有潜在线索', value: '有潜在线索' },
        { text: '有明确线索', value: '有明确线索' },
        { text: '有需求', value: '有需求' }
      ],
      show: true,
      list: [],
      total: 0,
      companyInfo: {},
      color1: '#999999',
      color2: '#212121',
      addform: {}
    }
  },
  created() {
    // this.search()
  },
  methods: {
    edit(item) {
      this.selectRequirementById(item.id)
    },
    async selectRequirementById(id) {
      const res = await selectRequirementById({ id })
      if (res.resultCode == 200) {
        this.addform = res.data
        this.DialogVisible = true
      }
    },
    async search() {
      this.queryInfo.customerId = this.customerId

      this.queryInfo.companyId = this.id
      const res = await selectRequirementPage(this.queryInfo)
      if (res.resultCode == 200) {
        this.list = res.data.list
        this.total = res.data.total
      }
    },
    clickMain() {
      if (this.queryInfo.orderByParam == 2) {
        this.search()
        this.color1 = '#999999'
        this.color2 = '#212121'
        this.queryInfo.orderByParam = 1
      } else {
        this.search()
        this.color1 = '#212121'
        this.color2 = '#999999'
        this.queryInfo.orderByParam = 2
      }
    },
    clickUp1() {
      this.queryInfo.orderByParam = 2
      this.search()
      this.color1 = '#212121'
      this.color2 = '#999999'
    },
    clickDown1() {
      this.queryInfo.orderByParam = 1
      this.search()
      this.color1 = '#999999'
      this.color2 = '#212121'
    },
    tabclick(index) {
      if (index == 0) {
        this.queryInfo.isRequirement = ''
      } else if (index == 1) {
        this.queryInfo.isRequirement = true
      } else {
        this.queryInfo.isRequirement = false
      }
      this.tabindex = index
      this.search()
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },
    // 重置按钮
    clearQueryInfo() {
      this.timeList = []
      this.queryInfo = { ...defaultQueryInfo }
      this.queryInfo.companyId = this.companyId
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog__header {
  padding: 20px 0px;
  padding-bottom: 10px;
}
::v-deep .el-dialog__title {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
  font-weight: 550;
  border-left: 4px solid #448aff;
  padding-left: 20px;
}
::v-deep .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 16px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.name_center {
  font-size: 16px;
  color: #333333;
}
.service_log {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 340px);
  padding: 20px 30px 10px 30px;
  box-sizing: border-box;
  background-color: #fff;
  .home_context_flex_box {
    height: calc(100vh - 300px);
    overflow: auto;
  }
  .box_history {
    margin: 10px 5px;
    padding: 15px 15px 10px 15px;
    border-radius: 10px;
    border: 1px solid #ececec;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box_history_top {
      font-size: 20px;
      font-weight: 550;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        border-radius: 4px;
      }
    }
    .dis_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .box_history_left {
      width: 1000px; /* 固定宽度超出隐藏 */
      margin-left: 10px;
    }
    .compay_flexs {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-bottom: 7px;
      color: #888686;
      overflow: hidden; /*  超出隐藏 */
      text-overflow: ellipsis; /*  超出以 ...显示 */
      display: -webkit-box;
      -webkit-line-clamp: 1; /* // 显示1行 */
      -webkit-box-orient: vertical;
      .left_trips {
        margin-bottom: 2px;
      }
    }
    .compay_flex {
      display: flex;
      align-items: center;
      font-size: 18px;
      height: 30px;
      .compay_flex_item {
        color: #448aff;
        border: 1px solid #448aff;
        font-size: 16px;
        padding: 2px 3px;
        margin-right: 5px;
        border-radius: 2px;
      }
    }
    .right_flex {
      display: flex;
      align-items: center;
      justify-content: right;
      .edit {
        color: #448aff;
        margin-right: 10px;
        cursor: pointer;
        font-size: 16px;
      }
      .css_state {
        font-size: 16px;
        margin-right: 10px;
      }
      .provinceData {
        height: 20px;
      }
      .delete {
        color: #fd5469;
      }
    }
    .time_css {
      color: #a1a1a1;
      font-size: 16px;
      margin-top: 10px;
      margin-right: 10px;
    }
   
    .box_history_flex {
      display: flex;
      position: relative;
      .isRequirement {
        background: #448aff;
        color: #fff;
        font-size: 14px;
        height: 20px;
        padding: 3px 5px;
        min-width: 50px;
        border-radius: 10px 10px 10px 0px;
        text-align: center;
        line-height: 20px;
      }
      .images_samll {
        width: 130px;
        height: 88px;
      }
      .isRequirement_false {
        background: #8679ff;
        color: #fff;
        font-size: 14px;
        padding: 3px 5px;
        height: 20px;
        min-width: 50px;
        border-radius: 10px 10px 10px 0px;
        text-align: center;
        line-height: 20px;
      }
      .isRequirement_false1 {
        background: #ff8c06;
        color: #fff;
        font-size: 14px;
        padding: 3px 5px;
        height: 20px;
        min-width: 50px;
        border-radius: 10px 10px 10px 0px;
        text-align: center;
        line-height: 20px;
      }
      .isRequirement_false2 {
        background: #1fd88e;
        color: #fff;
        font-size: 14px;
        padding: 3px 5px;
        height: 20px;
        min-width: 50px;
        border-radius: 10px 10px 10px 0px;
        text-align: center;
        line-height: 20px;
      }
      .po_isRequirement {
        position: absolute;
        left: -10px;
        top: -10px;
      }
    }
  }
  .label {
    margin-right: 10px;
  }
  .info_flex_box {
    display: flex;
    align-items: center;
    .name_css {
      color: #393939;
      font-size: 16px;
      margin-left: 0px;
    }
  }
  .main_box {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  .main {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  .arrUp {
    width: 0;
    height: 0;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 6px solid #c6c6c6;
    cursor: pointer;
  }

  .arrDown {
    width: 0;
    height: 0;
    margin-top: 2px;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-top: 6px solid #c6c6c6;
    cursor: pointer;
  }
  .info_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 10px;
    .flex_right {
      display: flex;
      align-items: center;
    }
    .tab_name_flex {
      display: flex;
      align-items: center;
      border: 1px solid #cccccc;
      border-left: none;
      border-radius: 4px;
    }
    .tab_name {
      width: 80px;
      height: 36px;
      font-size: 16px;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
      border-left: 1px solid #cccccc;
    }
    .bg {
      color: #fff;
      background: #448aff;
    }
  }
}
.new_page {
  margin: auto 0;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}
.search_right {
  // width: 560px;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}

.color2 {
  background-color: #82d066;
}
.color3 {
  background-color: #faad14;
}
.color4 {
  background-color: #13c2c2;
}
.color5 {
  background-color: #eb2f96;
}
.color6 {
  background-color: #fadb14;
}
</style>
